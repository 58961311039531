/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-kiss': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M12.493 13.368a7 7 0 112.489-4.858c.344.033.68.147.975.328a8 8 0 10-2.654 5.152 9 9 0 01-.81-.622m-3.731-3.22a13 13 0 00-1.107.318.5.5 0 11-.31-.95c.38-.125.802-.254 1.192-.343.37-.086.78-.153 1.103-.108.16.022.394.085.561.286.188.226.187.497.131.705a1.9 1.9 0 01-.31.593q-.115.16-.275.343.16.186.276.347c.142.197.256.397.31.595.055.208.056.479-.132.706-.168.2-.404.262-.563.284-.323.043-.733-.027-1.102-.113a15 15 0 01-1.191-.345.5.5 0 11.31-.95c.371.12.761.24 1.109.321q.264.062.446.084a6 6 0 00-.502-.584.5.5 0 01.002-.695 5.5 5.5 0 00.5-.577 5 5 0 00-.448.082zm.766-.087l-.003-.001-.003-.001zm.002 1.867l-.006.001zM6 8c.552 0 1-.672 1-1.5S6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8m2.757-.563a.5.5 0 00.68-.194.93.93 0 01.813-.493c.339 0 .645.19.813.493a.5.5 0 00.874-.486A1.93 1.93 0 0010.25 5.75c-.73 0-1.356.412-1.687 1.007a.5.5 0 00.194.68M14 9.828c1.11-1.14 3.884.856 0 3.422-3.884-2.566-1.11-4.562 0-3.421z"/>',
    },
});
